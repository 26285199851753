.sale{
  min-height: 25vh;
  border-radius:12px;
}

.sale-body{
  width: 95%;
  border-radius:12px;
  height: 100%;
  padding-left: 1rem;
  display:flex;
  flex-direction: column;
  justify-content:flex-end ;
  background: white;
  background-size:  100%;
  background-position:center ;
  background-repeat: no-repeat;
  cursor: pointer;
  h1{
    font-size: 4rem;
    width: max-content;
  }
  h2{
    width: max-content;
  }
}

.sale-total{
  background:  url(../media/total-icon.png),url(../media/total.png);
  background-repeat: no-repeat;
  background-size:  auto,100% 100%;
  background-position: top 7% right ,center ;
}
.sale-processin{
  background: url(../media/processing-icon.png),url(../media/processing.png);
  background-repeat: no-repeat;
  background-size:  auto,100% 100%;
  background-position: top 7% right ,center ;
}
.sale-pending{
  background: url(../media/pending-icon.png),url(../media/pending.png);
  background-repeat: no-repeat;
  background-size:  auto,100% 100%;
  background-position: top 7% right ,center ;
}
.sale-complete{
  background: url(../media/complete-icon.png),url(../media/complete.png);
  background-repeat: no-repeat;
  background-size:  auto,100% 100%;
  background-position: top 7% right ,center ;
}

@media screen and (max-width:568px) {
  .sale-total,
  .sale-processin,
  .sale-pending,
  .sale-complete {
    background-size:  45% ,100% 100%;
    }
}