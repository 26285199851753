.profile,
.company,
.address  {
  position: relative;
}

.profile::after,
.company::after,
.address::after {
  content: "Profile Information";
  position: absolute;
  width: max-content;
  top: 3rem;
  font-size: 1rem;
  left: -4rem;
  color: black!important;
}

.company::after {
  content: "Company Information";
}

.address::after {
  content: "Address";
  left:-1rem
}

@media (max-width: 768px) {
  .profile::after {
    content: "Profile";
    left: -0.5rem;
  }
  .company::after {
    content: "Company";
    left: -1.5rem;
  }
}
